import { gql } from '@apollo/client';

/*Local queries*/
export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export const GET_SENSORS = gql`
  query sensorsToRender {
    sensors @client {
      id
      name
      quantity
    }
  }
`;

/*Remote queries*/
export const GET_USER_INFO = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      username
      email
      firstName
      lastName
      phone
      user_type {
        id
        name
      }
    }
  }
`;

export const GET_STATION_DATA = gql`
  query getStationData(
    $stationID: ID
    $dateFrom: DateTime
    $dateTo: DateTime
    $offset: Int
    $limit: Int
  ) {
    getStationData(
      sort: "timestamp:desc"
      where: {
        station: { id: $stationID }
        timestamp_gte: $dateFrom
        timestamp_lte: $dateTo
        offset: $offset
        limit: $limit
      }
    )
  }
`;

export const GET_HELIX_DATA_BY_DEVICE_ID = gql`
  query getHelixDataByDeviceId(
    $deviceID: ID
    $windID: ID
    $agroID: ID
    $dateFrom: DateTime
    $dateTo: DateTime
  ) {
    tsHelixFulls(
      sort: "timestamp:desc"
      where: {
        device: { id: $deviceID }
        timestamp_gte: $dateFrom
        timestamp_lte: $dateTo
      }
    )
    tsWindsJson(
      sort: "timestamp:desc"
      where: {
        device: { id: $windID }
        timestamp_gte: $dateFrom
        timestamp_lte: $dateTo
      }
    )
    tsAgroesJson(
      sort: "timestamp:desc"
      where: {
        device: { id: $agroID }
        timestamp_gte: $dateFrom
        timestamp_lte: $dateTo
      }
    )
  }
`;

export const GET_APPLICATION_BY_ID = gql`
  query getApplication($applicationId: ID!) {
    application(id: $applicationId) {
      id
      chemical
      protection_interval
      date
      infection_type {
        name
        id
      }
    }
  }
`;

export const GET_PERONOSPORA_FOR_STATION = gql`
  query getPeronospora($stationId: ID!, $year: Int) {
    peronosporas(where: { station: $stationId, year: $year }) {
      station {
        id
        devices(where: { device_type: 2 }) {
          id
          sn
        }
      }
      peronosporaData
    }
  }
`;

export const GET_PERONOSPORA_YEARS = gql`
  query getPerenosporaYears($stationId: ID!) {
    peronosporas(where: { station: $stationId }) {
      year
    }
  }
`;

export const GET_PLESEN = gql`
  query getPlesen($stationId: ID!, $year: Int) {
    plesens(where: { station: $stationId, year: $year }) {
      plesenData
    }
  }
`;

export const GET_PLESEN_YEARS = gql`
  query getPlesenYears($stationId: ID!) {
    plesens(where: { station: $stationId }) {
      year
    }
  }
`;

export const GET_MUCNATKA = gql`
  query getMucnatka($stationId: ID!, $year: Int) {
    mucnatkas(where: { station: $stationId, year: $year }) {
      mucnatkaData
    }
  }
`;

export const GET_MUCNATKA_YEARS = gql`
  query getMucnatkaYears($stationId: ID!) {
    mucnatkas(where: { station: $stationId }) {
      year
    }
  }
`;

export const GET_BOTRYTIS_YEARS = gql`
  query getPerenosporaYears($stationId: ID!) {
    botrytis(where: { station: $stationId }) {
      year
    }
  }
`;

export const GET_BOTRYTIS = gql`
  query getBotrytis($stationId: ID!, $year: Int) {
    botrytis(where: { station: $stationId, year: $year }) {
      botrytisData
    }
  }
`;

export const GET_INFECTION_TABLE = gql`
  query getInfectionTable($stationId: ID!, $userId: ID!, $year: Int) {
    infectionTables(
      where: { station: $stationId, owner: $userId, year: $year }
    ) {
      infectionTableData
    }
  }
`;

export const GET_INFECTION_YEARS = gql`
  query getInfectionYears($stationId: ID!, $userId: ID!) {
    infectionTables(where: { station: $stationId, owner: $userId }) {
      year
    }
  }
`;

export const GET_STATIONS_BY_USER = gql`
  query getStationsByUser($email: String) {
    stations(
      where: {
        _or: [{ owner: { email: $email } }, { viewers: { email: $email } }]
      }
      sort: "name:asc"
    ) {
      id
      name
      latitude
      longitude
      Settings
      devices {
        id
        sn
        device_type {
          name
          type
        }
      }
    }
  }
`;

export const GET_STATION_POSITIONS = gql`
  query getStationPositions($email: String) {
    stations(
      where: {
        _or: [{ owner: { email: $email } }, { viewers: { email: $email } }]
      }
    ) {
      id
      name
      latitude
      longitude
      devices {
        device_type {
          type
        }
        id
      }
    }
  }
`;

export const GET_STATION_BY_ID = gql`
  query getStationById($id: ID!) {
    station(id: $id) {
      id
      name
      latitude
      longitude
      ground_level_altitude
      sea_level_altitude
      Settings
      devices {
        id
        sn
        device_type {
          name
          type
        }
      }
    }
  }
`;

export const GET_STATION_CURRENT_VALUES = gql`
  query getCurrentValues($stationIds: [ID]!, $intervalMins: Int) {
    currentValues(
      where: { stationIds: $stationIds, intervalMins: $intervalMins }
    ) {
      station
      last_temp
      last_rain
      last_pressure
      last_humidity
      last_irradiation
    }
  }
`;

export const GET_RAIN_AGGREGATES = gql`
  query getRainAggregates($stationIds: [ID]!) {
    rainAggregates(where: { stationIds: $stationIds }) {
      station
      rain1
      rain3
      rain30
      rain365
    }
  }
`;

export const GET_LOGGED_IN_USER = gql`
  query getLoggedInUser {
    me {
      id
      email
      username
      role {
        id
        name
      }
      user_type {
        id
        name
      }
    }
  }
`;

export const GET_CONDITION_METHODS = gql`
  query getMethod {
    alarmMethods {
      method
      id
    }
  }
`;

export const GET_CONDITIONS_BY_USER = gql`
  query getAlarmsByUser($email: String) {
    alarms(where: { owner: { email: $email } }) {
      id
      method {
        id
        method
      }
      name
      operator
      period
      unit
      value
      stations {
        id
        name
      }
    }
  }
`;

export const GET_EVENTS_BY_USER = gql`
  query getEventsByUser($email: String) {
    events(where: { alarm: { owner: { email: $email } } }) {
      id
      message
      created_at
      station {
        id
        name
      }
      alarm {
        name
      }
    }
  }
`;
